import { Box, BoxProps, Input } from "@chakra-ui/react"
import DefaultSections from "@components/Layouts/DefaultSections"
import { noop } from "instantsearch.js/es/lib/utils"
import { useRouter } from "next/compat/router"

const ProductLayout = ({ children, ...props }: BoxProps) => {
  const router = useRouter()
  return (
    <DefaultSections>
      <Input
        placeholder={"Search"}
        enterKeyHint={"search"}
        onFocus={() => {
          router?.push(`/s`).then(noop)
        }}
      />
      <Box
        mt={4}
        {...props}
      >
        {children}
      </Box>
    </DefaultSections>
  )
}

export default ProductLayout
