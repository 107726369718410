import { Box, Icon, VStack } from "@chakra-ui/react"
import ProductLayout from "@components/Layouts/ProductLayout"
import { NextSeo } from "next-seo"
import { TbError404 } from "react-icons/tb"

const Custom404 = () => {
  return (
    <ProductLayout>
      <NextSeo
        noindex={true}
        title={"Page Not Found"}
      />
      <VStack
        w={"full"}
        justifyContent={"center"}
        alignItems={"center"}
        h={"50vh"}
        fontSize={"2xl"}
      >
        <Icon
          as={TbError404}
          w={10}
          h={10}
        />
        <Box>{"Page Not Found"}</Box>
      </VStack>
    </ProductLayout>
  )
}

export default Custom404
